import React from 'react';
import { FileInput } from './FileInput';
import "../CSS/Style.css";

function App() {
  return (
    <div className="App">
      <FileInput />
    </div>
  );
}

export default App;
